import i18n from "@/i18n";
const WEEK_DAYS = [
    { value: 1, text: i18n.t('reportInfo.monday') },
    { value: 2, text: i18n.t('reportInfo.tuesday') },
    { value: 3, text: i18n.t('reportInfo.wednesday') },
    { value: 4, text: i18n.t('reportInfo.thursday') },
    { value: 5, text: i18n.t('reportInfo.friday') },
]

export {
    WEEK_DAYS
}