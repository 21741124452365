<template>
  <span style="vertical-align: top;">
  
    <v-text-field
      v-if="ismetric"
      style="
        display: inline-block;
        width: 33.333%;
        background-color: white;
      "
      type="number" @wheel="$event.target.blur()"
      min="0"
      dense
      outlined
      :label="placeholder + ' mm'"
      :value="mm"
      :disabled="readonly"
      @change="v => { mm = v; saveChanges(); }"
      @keypress="numberOnlyValidation($event)"
      hide-details="auto"
      maxlength="9" oninput="if (this.value && this.value.length > this.maxLength) { this.value=this.value.slice(0,this.maxLength) }"
      :class="{ 'errored' : errored }"
    ></v-text-field>

    <v-select
      v-else
      dense
      outlined
      :label="placeholder + ' (' + $t('reportInfo.inches') + ')'"
      :value="secondUnit"
      :disabled="readonly"
      :items="inchFractions"
      @change="v => { secondUnit = v; fractionsaveChanges(); }"
      style="display:inline-block;width:33.333%;background-color:white;"
      hide-details
      :class="{ 'errored' : errored }"
    >
      <template v-slot:item="{ item }">
        {{ item.text }}
      </template>
    </v-select>
  </span>
</template>

<script>
import { FRACTIONS, convertMMtoInches, convertInchesToMM, convertToInchesInt, convertToFraction } from '../utils/conversion'
import { isNumeric } from '@/utils/utils'

export default {
  data() {
    return {
      x: 0,
      y: 0,
      modalOpen: false,
      inchFractions: FRACTIONS,
      mm: this.convertInchesToMM(this.valueInInches),
      firstUnit: this.convertToInchesInt(this.valueInInches),
      secondUnit: this.convertToFraction(this.valueInInches)
    };
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    ismetric: {
      type: Boolean,
      default: false,
    },
    valueInInches: {
      type: Number,
    },
    objectToUpdate: {
      type: Object,
      default: null,
    },
    errored: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // m = ft / 3,28084
    // ft = m * 3,28084
    prettyValueInInches() {
      let completeValue = this.valueInInches;

      let valueInt = Math.trunc(this.valueInInches);
      let fraction = parseFloat(completeValue - valueInt);

      // If the value was in MM but need to be in inches, find the nearest value in inches.
      let foundFraction = this.inchFractions.find((x) => x.value == fraction);
      if (!foundFraction) {
        fraction = this.convertToFraction(completeValue)
      }

      //var fraction = 0.03125;
      var len = fraction.toString().length - 2;

      var denominator = Math.pow(10, len);
      var numerator = fraction * denominator;

      var divisor = this.gcd(numerator, denominator); // Should be 5

      numerator /= divisor;
      denominator /= divisor;

      var prettyFraction =
        numerator == 0 && (this.valueInInches != 0)
          ? "" + '"'
          : ((numerator == 0 && this.valueInInches == 0) ? '0 "' :  " " + Math.floor(numerator) + "/" + Math.floor(denominator) + '"');

      return this.valueInInches != null
        ? (valueInt == 0 ? "" : valueInt)  + prettyFraction
        : "";
    },
  },
  methods: {
    gcd(a, b) {
      if (b < 0.0000001) return a; // Since there is a limited precision we need to limit the value.

      return this.gcd(b, Math.floor(a % b)); // Discard any fractions due to limitations in precision.
    },
    getModelValue() {
      if (this.ismetric) {
        return this.mm ? this.convertMMtoInches(this.mm) : null;
      } else {

        // If the value is not a number, set it to null if value is empty string
        if (this.firstUnit != null && !isNumeric(this.firstUnit) && this.firstUnit.length == 0) {
            this.firstUnit = null;
        }
        if (this.secondUnit != null && !isNumeric(this.secondUnit) && this.secondUnit.length == 0) {
            this.secondUnit = null;
        }

        var first = this.firstUnit != null ? parseFloat(this.firstUnit) : null;
        var second = this.secondUnit != null ? parseFloat(this.secondUnit) : null;
        
        // If there is no value for the fraction, we consider it to be 0
        if (second == null) {
          second = 0;
        }

        if (first != null && second != null) {
          return first + second;
        }
        else {
          return null;
        }
      }
    },
    convertMMtoInches: convertMMtoInches, 
    convertInchesToMM: convertInchesToMM, 
    convertToInchesInt: convertToInchesInt, 
    convertToFraction: convertToFraction,
    inchsaveChanges() {
      // If the first unit was erased and the fraction is set, we erase the fraction.
      if (!isNumeric(this.firstUnit) && isNumeric(this.secondUnit)) {
        this.secondUnit = null;
      }
      this.saveChanges();
    },
    fractionsaveChanges() {
      // If only the second unit was set and the first unit is null, we default it to 0.
      if (!this.firstUnit && isNumeric(this.secondUnit)) {
        this.firstUnit = 0;
      }
      this.saveChanges();
    },
    saveChanges() {
      return this.$emit("changed", this.getModelValue(), this.objectToUpdate);
    },
    onNumberInput(event) {
      this.firstUnit = parseInt(this.firstUnit.toString().replace(".", ""));
      console.log("On Input ", event);
      /* if(event.key==='.'){
           event.preventDefault();
       }*/
    },
    numberOnlyValidation(event) {
      const pattern = /[0-9]/;
      let inputChar = String.fromCharCode(event.charCode);

      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    },
    keydownPrevent(event) {
      event.preventDefault();
    },
    openModal(event) {
      this.x = event.clientX;
      this.y = event.clientY;
      this.modalOpen = true;
    },
  },
};
</script>
<style scoped>
.inches-dialog {
  border: 1px solid #efefef;
  border-radius: 8px;
}
</style>