<template>
    <v-row class="ma-0">
      <v-col cols="6" class="pa-0  mt-3">
        <v-text-field
          :class="{ 'errored' : errored }"
          v-bind:key="firstUnit"
          dense
          min="0"
          style="background-color: white;"
          type="number" @wheel="$event.target.blur()"
          :outlined="outlined"
          :label="isMetricData ? 'm' : $t('general.ft')"
          :value="firstUnit"
          :disabled="readonly"
          @keypress="numberOnlyValidation($event)"
          @change="v => { firstUnit = v; saveChanges(); }"
          hide-details="auto"
          maxlength="9" oninput="if (this.value && this.value.length > this.maxLength) { this.value=this.value.slice(0,this.maxLength) }"
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="pa-0  mt-3">
        <v-text-field
          :class="{ 'errored' : errored }"
          v-bind:key="secondUnit"
          dense
          min="0"
          style="background-color: white;"
          type="number" @wheel="$event.target.blur()"
          :outlined="outlined"
          :label="isMetricData ? 'cm' : $t('reportInfo.inches')"
          :value="secondUnit"
          :disabled="readonly"
          @keypress="numberOnlyValidation($event)"
          @change="v => { secondUnit = v; saveChanges(); }"
          hide-details="auto"
          maxlength="9" oninput="if (this.value && this.value.length > this.maxLength) { this.value=this.value.slice(0,this.maxLength) }"
        ></v-text-field>
      </v-col>
      <v-alert
        v-if="depthDuplicate"
        cols="12"
        dense
        type="error"
        class="pile-alert"
        style="width:100%;margin-top:2px;">
        {{ $t('reportInfo.duplicate') }}
      </v-alert>
    </v-row>
</template>

<script>
import { feetsToMetersIntOnly, feetsToOnlyCentimeters, onlyFeets, onlyInches } from '../utils/conversion'
 
export default {
  data() {
    return {
      firstUnit: null,
      secondUnit: null,
      isMetricData: false,
      depthDuplicate: false, 
      rules: []
    };
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    ismetric: {
      type: Boolean,
      default: false,
    },
    valueInFt: {
      type: Number,
    },
    objectToUpdate: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false
    },
    errored: {
      type:Boolean,
      default: false
    },
    maxValue: {
      type: Number,
    }
  },
  mounted() {
    //console.log('MeasureFields mounted')
    this.updateValues(this.ismetric);
  },
  methods: {
    feetsToMetersIntOnly: feetsToMetersIntOnly,
    feetsToOnlyCentimeters: feetsToOnlyCentimeters, 
    onlyFeets: onlyFeets, 
    onlyInches: onlyInches,
    getModelValue() {
      var first = this.firstUnit ? parseFloat(this.firstUnit) : 0
      var second = this.secondUnit ? parseFloat(this.secondUnit) : 0;

      var metric = first + second / 100;

      var feets = first + second / 12;

      var metricToFeet = metric / 0.3048;

      return this.isMetricData ? metricToFeet : feets;
    },
    updateValues(isMetric) {
      this.isMetricData = isMetric;
      this.firstUnit = this.isMetricData ? this.feetsToMetersIntOnly(this.valueInFt) : this.onlyFeets(this.valueInFt);
      this.secondUnit = this.isMetricData ? this.feetsToOnlyCentimeters(this.valueInFt) : this.onlyInches(this.valueInFt);
    },
    setDepthDuplicate(depthDuplicate) {
      this.depthDuplicate = depthDuplicate;
    },
  
    saveChanges() {//We can't change multiple values at the same time, as soon as we change one, the control is refreshed. so we have to setTimeout to change a value
      if (this.isMetricData && this.secondUnit > 99) {
        this.firstUnit = parseInt(this.firstUnit || 0) + Math.floor(this.secondUnit / 100);
        let tmp = this.secondUnit % 100;
        let inst = this;
        setTimeout(function () {
          inst.secondUnit = tmp;
          inst.saveChanges();
        });
      }
      else if (!this.isMetricData && this.secondUnit > 11) {
        this.firstUnit = parseInt(this.firstUnit || 0) + Math.floor(this.secondUnit / 12);
        let tmp = this.secondUnit % 12;
        let inst = this;
        setTimeout(function () {
          inst.secondUnit = tmp;
          inst.saveChanges();
        });
      }
      if (this.maxValue) {
        if (this.firstUnit > this.maxValue) {
          let inst = this;
          setTimeout(() => {
            inst.firstUnit = inst.maxValue;
            inst.saveChanges();
          });
        }
      }
      return this.$emit("changed", this.getModelValue(), this.objectToUpdate);
    },
    numberOnlyValidation(event) {
      const pattern = /[0-9]/;
      let inputChar = String.fromCharCode(event.charCode);

      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
</style>